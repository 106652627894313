import { createContext, Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { MediaFileType } from '../../models/medias/types';
import { BackgroundUploader } from '.';

type BackgroundUploaderContextType = {
  addFile: (file: File, libraryName: string, abortController: AbortController) => string;
  addAbortAPIToFile: (fileId: string, abortAPI: () => void) => void;
  canBeClosed: boolean;
  canCancelAll: boolean;
  canCancelUpload: (fileId: string) => boolean;
  cancelAllUploads: () => void;
  cancelUpload: (fileId: string) => void;
  closeUploader: () => void;
  isClosed: boolean;
  isMinimised: boolean;
  itemCountByStatus: Record<string, number>;
  setIsMinimised: Dispatch<SetStateAction<boolean>>;
  updateFileError: (params: UpdateFileErrorParamsType) => void;
  updateFileProgress: (params: UpdateFileProgressParamsType) => void;
  updateFileReady: (fileId: string) => void;
  uploadingFiles: Record<string, UploadingFileType>;
};

export const BackgroundUploaderContext = createContext({} as BackgroundUploaderContextType);

export const BackgroundUploaderProvider = ({ children }: Component) => {
  const [uploadingFiles, setUploadingFiles] = useState<Record<string, UploadingFileType>>({});
  const [isMinimised, setIsMinimised] = useState(false);
  const [isClosed, setIsClosed] = useState(true);
  const itemCountByStatus = useMemo(
    () =>
      Object.values(uploadingFiles).reduce(
        (acc, uploadingFile) => {
          const { status } = uploadingFile;

          return {
            ...acc,
            [status]: acc[status] + 1,
          };
        },
        { Uploading: 0, Uploaded: 0, Errored: 0, Aborted: 0, Ready: 0, Processing: 0, Deleted: 0, UploadAborted: 0 }
      ),
    [uploadingFiles]
  );

  const canBeClosed = useMemo(() => Object.values(uploadingFiles).every(({ status }) => status !== 'Uploading'), [uploadingFiles]);
  const canCancelAll = useMemo(() => !!itemCountByStatus.Uploading, [itemCountByStatus.Uploading]);

  useEffect(() => {
    if (canBeClosed) {
      setIsMinimised(false);
    }
  }, [canBeClosed]);

  const closeUploader = () => {
    setIsClosed(true);
    setUploadingFiles({});
  };

  const addFile = (file: File, libraryName: string, abortController: AbortController) => {
    const fileId = Math.random().toString();
    let type: MediaFileType | undefined;

    if (file.type.includes('image')) {
      type = MediaFileType.Image;
    } else if (file.type.includes('video')) {
      type = MediaFileType.Video;
    }

    setIsMinimised(false);
    setIsClosed(false);
    setUploadingFiles((prev) => {
      return {
        ...prev,
        [fileId]: {
          id: fileId,
          name: file.name,
          mediaType: type,
          status: 'Uploading',
          libraryName,
          uploadProgress: 1,
          mediaSize: file.size,
          abortController,
        },
      };
    });

    return fileId;
  };

  const updateFileProgress = ({ fileId, uploadProgress, abortController }: UpdateFileProgressParamsType) => {
    setUploadingFiles((items) => {
      if (items[fileId]) {
        return {
          ...items,
          [fileId]: {
            ...items[fileId],
            id: fileId,
            uploadProgress,
            abortUpload:
              abortController &&
              (() => {
                abortController.abort();
              }),
          },
        };
      }

      return { ...items };
    });
  };

  const updateFileError = ({ fileId, errorMessage }: UpdateFileErrorParamsType) => {
    setIsMinimised(false);
    setUploadingFiles((items) => {
      if (items[fileId]) {
        return {
          ...items,
          [fileId]: {
            ...items[fileId],
            errorMessage,
            status: items[fileId].status !== 'Aborted' ? 'Errored' : 'Aborted',
          },
        };
      }

      return { ...items };
    });
  };

  const updateFileReady = (fileId: string) => {
    setUploadingFiles((items) => {
      if (items[fileId]) {
        return {
          ...items,
          [fileId]: {
            ...items[fileId],
            id: fileId,
            status: 'Uploaded',
          },
        };
      }

      return { ...items };
    });
  };
  const addAbortAPIToFile = (fileId: string, abortAPI: () => void) => {
    setUploadingFiles((items) => {
      if (items[fileId]) {
        return {
          ...items,
          [fileId]: {
            ...items[fileId],
            abortAPI,
          },
        };
      }

      return { ...items };
    });
  };
  const cancelAllUploads = () => {
    if (canCancelAll) {
      Object.values(uploadingFiles).forEach((uploadingFile) => {
        const { status, abortUpload, abortAPI, abortController } = uploadingFile;

        if (status !== 'Uploaded' && status !== 'Aborted' && status !== 'Errored') {
          abortController.abort();

          if (abortUpload) {
            abortUpload();
          }
          if (abortAPI) {
            abortAPI();
          }

          setUploadingFiles((items) => {
            if (items[uploadingFile.id]) {
              return {
                ...items,
                [uploadingFile.id]: { ...uploadingFile, status: 'Aborted' },
              };
            }

            return { ...items };
          });
        }
      });
    }
  };

  const cancelUpload = (fileId: string) => {
    if (uploadingFiles[fileId]) {
      const { abortUpload, abortAPI, abortController } = uploadingFiles[fileId];

      abortController.abort();

      if (abortUpload && abortAPI) {
        abortUpload();
        abortAPI();
      }
      setUploadingFiles((items) => {
        if (items[fileId]) {
          return {
            ...items,
            [fileId]: {
              ...(items[fileId] || {}),
              status: 'Aborted',
            },
          };
        }

        return { ...items };
      });
    }
  };
  const canCancelUpload = (fileId: string) => !!uploadingFiles[fileId].abortController;

  return (
    <BackgroundUploaderContext.Provider
      value={{
        addAbortAPIToFile,
        addFile,
        canBeClosed,
        canCancelAll,
        canCancelUpload,
        cancelAllUploads,
        cancelUpload,
        closeUploader,
        isClosed,
        isMinimised,
        itemCountByStatus,
        setIsMinimised,
        updateFileProgress,
        updateFileError,
        updateFileReady,
        uploadingFiles,
      }}
    >
      {!isClosed && createPortal(<BackgroundUploader />, document.getElementById('root') as HTMLElement)}
      {children}
    </BackgroundUploaderContext.Provider>
  );
};

export const useBackgroundUploaderContext = (): BackgroundUploaderContextType => useContext(BackgroundUploaderContext);
