import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { fetchWithHeaders, urls } from '@sendible/common';
import { useToastNotificationContext } from '@sendible/design-system';
import { NotificationTypeEnum } from '@sendible/design-system/src/components/molecules/Toast';
import { useTranslation } from 'react-i18next';
import endpoints from '../../data-layer/endpoints';
import { useMediaLibraryContext } from '../../pages/MediaLibrary/context';
import { useInfiniteQueryWithAccessToken, useMutationWithAccessToken } from '../../data-layer/useReactQueryWithAccessToken';
import { mediaRenameFromPreview } from '../../pages/MediaLibrary/pendoEvents';

export const useGetMediasQueryKey = (): DLInfiniteQueryKey => {
  const { activeMediaLibrary, mediaSearch, perPage } = useMediaLibraryContext();
  const paramObj = {
    mediaLibraryId: activeMediaLibrary?.id,
    name: mediaSearch,
    status: 'Ready,Uploaded,Processing,New',
    perPage,
  };

  return [endpoints.GetMedias.endpoint, paramObj];
};

export const useInfiniteGetMedias = () => {
  const { activeMediaLibrary } = useMediaLibraryContext();
  const mediasQueryKey = useGetMediasQueryKey();

  return useInfiniteQueryWithAccessToken<MediaType[]>(mediasQueryKey, {
    enabled: !!activeMediaLibrary?.id,
    refetchInterval: endpoints.GetMedias.interval,
  });
};

export const useDeleteMedia = (pendoEvent: string, mediaType?: string) => {
  const queryClient = useQueryClient();
  const mediasQueryKey = useGetMediasQueryKey();
  const { selectedMediasIds, setSelectedMediasIds } = useMediaLibraryContext();
  const { t } = useTranslation('media_library');
  const { method, endpoint } = endpoints.DeleteMedia;
  const { showToastNotification } = useToastNotificationContext();

  return useMutationWithAccessToken<MediaType, MediaMutationParamsType>({
    method,
    endpoint,
    options: {
      onSuccess: useCallback(
        ({ media_id, name }) => {
          showToastNotification({
            notificationText: t('toast_single_media_deletion_success', { mediaName: name }) || '',
            type: NotificationTypeEnum.Success,
            showCloseButton: true,
          });

          window.pendo.track(pendoEvent, {
            name,
            type: mediaType,
          });
          queryClient.invalidateQueries(mediasQueryKey);

          // If the media is selected, deselect it
          if (selectedMediasIds.includes(media_id)) {
            const newList = [...selectedMediasIds];

            newList.splice(newList.indexOf(media_id), 1);
            setSelectedMediasIds(newList);
          }
        },
        [selectedMediasIds]
      ),
      onError: useCallback((_, { name }) => {
        showToastNotification({
          notificationText: t('toast_medias_deletion_error', { name }) || '',
          type: NotificationTypeEnum.Error,
          showCloseButton: true,
        });
      }, []),
    },
  });
};

export const useDeleteMultipleMedias = (pendoEvent: string) => {
  const queryClient = useQueryClient();
  const mediasQueryKey = useGetMediasQueryKey();
  const { t } = useTranslation('media_library');
  const { showToastNotification } = useToastNotificationContext();

  const { method, endpoint } = endpoints.DeleteMedia;

  return useMutationWithAccessToken<MediaType[], { selectedMediasIds: string[]; access_token: string }>({
    method,
    endpoint,
    options: {
      onSuccess: (selectedMediasIds) => {
        queryClient.invalidateQueries(mediasQueryKey);

        showToastNotification({
          notificationText: t('toast_multiple_medias_deletion_success', { numberOfMedias: selectedMediasIds.length }) || '',
          type: NotificationTypeEnum.Success,
          showCloseButton: true,
        });

        window.pendo.track(pendoEvent, { selectedMediasIds });
      },
      onError: () => {
        showToastNotification({
          notificationText: t('toast_multiple_medias_deletion_error') || '',
          type: NotificationTypeEnum.Error,
          showCloseButton: true,
        });
      },
    },
    mutationFn: async ({ selectedMediasIds, access_token }) => {
      const responses: PromiseSettledResult<MediaType>[] = await Promise.allSettled(
        selectedMediasIds.map((mediaId) =>
          fetchWithHeaders({
            method,
            url: `${urls.baseUrl}${endpoint}`,
            params: { media_id: mediaId, access_token },
          }).then(({ result }) => result)
        )
      );

      const rejectedResponses = responses.filter((response) => response.status === 'rejected');

      if (rejectedResponses.length > 0) {
        throw new Error();
      }

      return responses.filter((response) => response.status === 'fulfilled').map((response) => (response as PromiseFulfilledResult<MediaType>).value);
    },
  });
};

export const useRenameMedia = (mediaType: string, newName: string) => {
  const { t } = useTranslation('media_library');
  const queryClient = useQueryClient();
  const mediasQueryKey = useGetMediasQueryKey();
  const { showToastNotification } = useToastNotificationContext();

  return useMutationWithAccessToken<MediaType, MediaMutationParamsType>({
    method: endpoints.RenameMedia.method,
    endpoint: endpoints.RenameMedia.endpoint,
    body: { name: newName },
    options: {
      onSuccess: useCallback(({ name }) => {
        showToastNotification({
          notificationText: t('toast_media_rename_success') || '',
          type: NotificationTypeEnum.Success,
          showCloseButton: true,
        });

        window.pendo.track(mediaRenameFromPreview, {
          name,
          type: mediaType,
        });
        queryClient.invalidateQueries(mediasQueryKey);
      }, []),
      onError: useCallback(() => {
        showToastNotification({
          notificationText: t('toast_media_rename_error') || '',
          type: NotificationTypeEnum.Error,
          showCloseButton: true,
        });
      }, []),
    },
  });
};
