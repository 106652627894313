import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { EVENTS, publish, subscribe, subscribeOnce } from '@sendible/event-manager';
import { routeUtils } from '@sendible/common';
import { isFEV2Route } from '@sendible/frontendv2';
import { ToastNotification, useToastNotificationContext } from '@sendible/design-system/src';
import SharedStateBridge, { BridgeContextProvider, SharedStateType } from '@sendible/shared-state-bridge';
import { FrontendV2Page } from '@sendible/frontendv2/src/Frontendv2Page';
import AiAssist from '@sendible/frontendv2/src/components/AiAssist';
import { BackgroundUploaderProvider } from '@sendible/frontendv2/src/components/BackgroundUploader/context';
import PageContent from './components/PageContent';
import BackboneContainer from './components/BackboneContainer';
import switchUser from './switchUser';

interface Sendible360AdapterProps {
  onTheme: (theme: string) => void;
}

const Sendible360Adapter = ({ onTheme }: Sendible360AdapterProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const aiAssistStateDefault = {
    open: false,
    quickAction: null,
    composeText: '',
  };

  const [isFrontendv2Route, setisFrontendv2Route] = useState(false);
  const [aiAssistOptions, setAiAssistOptions] = useState(aiAssistStateDefault);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const [sharedState, setSharedState] = useState<SharedStateType>(undefined!);
  const { showToastNotification } = useToastNotificationContext();

  useEffect(() => {
    import('@sendible/sendible360');
    subscribe(EVENTS.SWITCH_USER, switchUser);

    const unsubscribePageChange = subscribe(EVENTS.CHANGE_PAGE, (route: string) => {
      const cleanRoute = routeUtils.cleanRoute(route);
      const isNewAppRoute = isFEV2Route(route);

      if (isNewAppRoute) {
        navigate(`/${cleanRoute}`);
      } else {
        navigate(`/#${cleanRoute}`);
      }
    });
    const unsubscribeSharedState = subscribeOnce(EVENTS.LOCAL_STORAGE_CHANGED, async (key: string) => {
      const newContext = SharedStateBridge.getSharedState();

      setSharedState(newContext);
    });

    // events for modal features
    const unsubscribeAIAssistOpen = subscribe(EVENTS.SHOW_AI_ASSIST, ({ quickAction, composeText }) => {
      setAiAssistOptions({ open: true, quickAction, composeText });
    });
    const unsubscribeAIAssistClose = subscribe(EVENTS.CLOSE_AI_ASSIST, () => {
      setAiAssistOptions(aiAssistStateDefault);
    });

    const unsubscribeToastNotification = subscribe(EVENTS.TOAST_NOTIFICATION_SHOW, (notificationParams) => {
      showToastNotification(notificationParams);
    });

    return () => {
      unsubscribePageChange();
      unsubscribeAIAssistOpen();
      unsubscribeAIAssistClose();
      unsubscribeSharedState();
      unsubscribeToastNotification();
    };
  }, []);

  useEffect(() => {
    if (window?.appConfig?.branding?.themeurl) onTheme(window.appConfig.branding.themeurl);
  }, [window?.appConfig?.branding?.themeurl]);

  useEffect(() => {
    const { classList } = document.body;
    const { pathname } = location;
    const isNewAppRoute = isFEV2Route(pathname);

    setisFrontendv2Route(isNewAppRoute);

    classList.remove('v2-app');

    if (isNewAppRoute) {
      window.Backbone?.history.navigate('', { trigger: false });
      publish(EVENTS.ROUTE_TO_V2, pathname);
      classList.add('v2-app');
    } else {
      window.Backbone?.history.navigate(location.hash, { trigger: true });
    }
  }, [location]);

  useEffect(() => {
    const unsubscribe = subscribe(EVENTS.FINISH_360_BOOTSTRAP, () => {
      setIsLoading(false);
    });

    const stopLoading = () => {
      if (isLoading) {
        setIsLoading(false);
        setHasError(true);
      }
    };
    const timeout = setTimeout(stopLoading, 45000);

    return () => {
      clearInterval(timeout);
      unsubscribe();
    };
  }, [isLoading]);

  return (
    <BackboneContainer
      shouldDisplay={!isLoading}
      hasError={hasError}
    >
      <BackgroundUploaderProvider>
        <BridgeContextProvider initialState={sharedState}>
          <ToastNotification />
          {isFrontendv2Route && !isLoading && <FrontendV2Page />}
          {aiAssistOptions.open && (
            <AiAssist
              quickAction={aiAssistOptions.quickAction}
              composeText={aiAssistOptions.composeText}
            />
          )}
        </BridgeContextProvider>
        <PageContent isFrontendv2Route={isFrontendv2Route} />
      </BackgroundUploaderProvider>
    </BackboneContainer>
  );
};

export default Sendible360Adapter;
